export const offset = (el) => {
  try {
    if (!el || !el.getClientRects().length) {
      return { top: 0, left: 0 };
    }
  } catch (e) {
    console.log(el);
    console.log(e);
  }
  const rect = el.getBoundingClientRect();
  const win = el.ownerDocument.defaultView;
  return ({
    top: rect.top + win.pageYOffset,
    left: rect.left + win.pageXOffset,
  });
};

export const baseOffset = (rootelement) => offset(rootelement).top;

export const getDistance = (el1, el2) => Math.abs(offset(el1).top - offset(el2).top);

export const isBetween = (v, min, max) => (v > min && v < max);

export const scrollToChapter = (num) => {
  const el = document.getElementById(`ch${num}`);
  const position = offset(el);
  window.scrollTo({ top: position });
};

export const isInView = (top, bottom, offs) => (bottom >= offs && top <= offs + window.innerHeight);

export const isBelowTop = (pos, offs) => (pos > offs);

export const isAboveTop = (pos, offs) => (pos < offs);

export const isBelowBottom = (pos, offs) => isBelowTop(pos, offs + window.innerHeight);

export const isAboveBottom = (pos, offs) => isAboveTop(pos, offs + window.innerHeight);

export const scrollOffset = () => window.pageYOffset;

const getElementHeight = (obj) => obj.offsetHeight || obj.clientHeight;

const getElementWidth = (obj) => obj.offsetWidth || obj.clientWidth;

const getElementTop = (obj) => offset(obj).top;

const getElementBottom = (obj) => {
  if (!obj) {
    return scrollOffset() + window.innerHeight;
  }
  if (getElementHeight(obj)) {
    return getElementTop(obj) + getElementHeight(obj);
  }
  return obj.nextSibling ? getElementTop(obj.nextSibling) : getElementBottom(obj.parent);
};

export const getBoundary = (obj) => {
  if (!obj) {
    return {
      top: 0, bottom: Math.infinity, height: Math.infinity, width: Math.infinity,
    };
  }
  const top = getElementTop(obj);
  const bottom = getElementBottom(obj);
  return {
    top,
    bottom,
    height: getElementHeight(obj) || (bottom - top),
    width: getElementWidth(obj) || Math.infinity,
  };
};
