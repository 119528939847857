import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const VimeoVideo = ({
  vimeoID, className, ...rest
}) => (
  <div className={classnames('vimeo-wrapper', className)} {...rest}>
    <div className="videoframe" style={{ padding: '70% 0px 0px 0px', position: 'relative' }}>
      <iframe
        title={vimeoID}
        style={{
          position: 'absolute', top: 0, left: 0, width: '100%', height: ' 100%',
        }}
        src={`https://player.vimeo.com/video/${vimeoID}`}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      />
    </div>
  </div>
);

export default VimeoVideo;

VimeoVideo.propTypes = {
  vimeoID: PropTypes.string.isRequired,
  className: PropTypes.string,
};

VimeoVideo.defaultProps = {
  className: '',
};
