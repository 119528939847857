import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const ClearPlaqueLarge = ({
  children, className, ...rest
}) => (
  <div className={classnames('plaque', 'clear', 'large', className)} {...rest}>
    {children}
  </div>
);

export default ClearPlaqueLarge;

ClearPlaqueLarge.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

ClearPlaqueLarge.defaultProps = {
  children: null,
  className: '',
};
