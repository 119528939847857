import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Title = ({
  children, className, ...rest
}) => (
  <div className={classnames('content-title', className)} {...rest}>
    <h1 className="bigText">
      {children}
    </h1>
  </div>
);

export default Title;

Title.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Title.defaultProps = {
  children: null,
  className: '',
};
