import React from 'react';
import PropTypes from 'prop-types';

const LayerWrapper = ({
  children, ...rest
}) => (
  <div className="layerWapper" {...rest}>
    {
      // eslint-disable-next-line react/no-array-index-key
      children.map((child, index) => (<div key={index} style={{ position: 'absolute' }}>{child}</div>))
    }
  </div>
);

export default LayerWrapper;

LayerWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
