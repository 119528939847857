import {
  offset, isBetween,
} from './position';

export const setImageFullyInView = (el, currentOffset) => {
  const { top } = offset(el);
  if (isBetween(top, currentOffset, currentOffset + window.innerHeight)
    && (isBetween(top + el.height, currentOffset, currentOffset + window.innerHeight))) {
    el.classList.add('inView');
  } else {
    el.classList.remove('inView');
  }
};

export const dofadeInFullscreenImage = (element, currentOffset) => {
  const { top } = offset(element);
  element.querySelectorAll('div.waitToFade').forEach((div) => {
    if (top < currentOffset + (window.innerHeight / 2)) {
      div.classList.add('fadeIn');
      div.classList.remove('waitToFade');
    }
  });
};
