/**
  <LottieLoop file=[jsonfile] (name,external,[standard attributes]) />

  file: the name of a json file in the assets/lottie folder, unless external is set
  external: if set, the file is an absolute file path
  name: if you reuse a json file, the name need to be specified as a unique name.

  standard attributes: style className etc.
*/
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import lottieWeb from 'lottie-web';

const LottieLoop = ({
  file, name, external, renderersettings, className, ...rest
}) => {
  const refAnimation = useRef();

  const animationName = name || file;
  const lottieFileName = external ? file : `./assets/lottie/${file}`;

  useEffect(() => {
    lottieWeb.loadAnimation({
      container: refAnimation.current,
      path: lottieFileName,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      name: animationName,
      rendererSettings: renderersettings,
    });
  }, []);

  return (
    <div ref={refAnimation} className={classnames(className)} {...rest} />
  );
};

export default LottieLoop;

LottieLoop.propTypes = {
  file: PropTypes.string.isRequired,
  name: PropTypes.string,
  external: PropTypes.bool,
  renderersettings: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  className: PropTypes.string,
};

LottieLoop.defaultProps = {
  name: undefined,
  external: false,
  renderersettings: {},
  className: '',
};
