import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const currentYear = (new Date().getFullYear());

const Copyright = ({
  className, ...rest
}) => (
  <div className={classnames('copyright', className)} {...rest}>
    <span>
      &copy;
      {` Copyright ${currentYear}`}
    </span>
  </div>
);

export default Copyright;

Copyright.propTypes = {
  className: PropTypes.string,
};

Copyright.defaultProps = {
  className: '',
};
