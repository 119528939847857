import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import useScrollPosition from '@react-hook/window-scroll';

import { getBoundary, isInView } from '../../utils/position';

const ParallaxImage = ({
  file, className, children, ...rest
}) => {
  const scrollPosition = useScrollPosition(60);
  const refDiv = useRef();

  useEffect(() => {
    // if (!refDiv) {
    //   return;
    // }
    // const div = refDiv.current;
    // const { top, bottom } = getBoundary(div);
    // if (!isInView(top, bottom, scrollPosition)) {
    //   return;
    // }
    // TODO: move background image at half speed in the opposite direction
    // when top is equal to scrollPosition, backgroundImage should be at 0%
    // when top - height is equal to scrollPosition , backgroundImage should be at -50%
    // when top + height is equal to scrollPosition , backgroundImage should be at 50%

    // const height = window.innerHeight;
    // const imgpos = -((top - height) - scrollPosition);
    // const percent = ((imgpos / height) * 0.5) - 0.5;
    // const pixels = Math.round(height * percent);
    // div.style.top = `${pixels}px`;
  }, [scrollPosition]);

  return (
    <div className={classnames('parallaxImage', className)} {...rest}>
      <div ref={refDiv} className="coverImage" style={{ position: 'absolute', backgroundImage: `url(${file})` }} />
      {children}
    </div>
  );
};

export default ParallaxImage;

ParallaxImage.propTypes = {
  children: PropTypes.node,
  file: PropTypes.string.isRequired,
  className: PropTypes.string,
};

ParallaxImage.defaultProps = {
  children: null,
  className: '',
};
