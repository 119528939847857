/**
  Articles over video

  Background video with Article children, dynamic length, depends on the Articles

  file: required, name without extension and size of the mp4 video in /assets/video folder
    the complete file name is built up by the name, a dash, the size, and .mp4
  size: required, any of the sizes available for the video

  fps: the video framerate, defaults to 24
  ppf: pixelsPerFrame, how far the scroll moves each frame, defaults to an arbitrary value of 50 ??
*/
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const VideoSection = ({
  file, size, fps, ppf, children,
}) => {
  const refWrapper = useRef();

  useEffect(() => {
    const sectionVideo = refWrapper.current.querySelector('video');
    sectionVideo.addEventListener('loadedmetadata', () => {
      const { duration } = sectionVideo;
      sectionVideo.setAttribute('data-duration', duration);
      sectionVideo.pause();
    });
  }, []);

  return (
    <section ref={refWrapper} className="video">
      <aside>
        <video tabIndex="0" autobuffer="autobuffer" preload="auto" autoPlay playsInline muted data-fps={fps} data-ppf={ppf}>
          <source type="video/mp4" src={`./assets/videos/${file}-${size}.mp4`} />
          <p>Sorry, your browser does not support the &lt;video&gt; element.</p>
        </video>
      </aside>
      {children}
    </section>
  );
};

export default VideoSection;

VideoSection.propTypes = {
  children: PropTypes.node,
  file: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  fps: PropTypes.number,
  ppf: PropTypes.number,
};

VideoSection.defaultProps = {
  children: null,
  fps: 24,
  ppf: 50,
};
