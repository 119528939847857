import React from 'react';
import PropTypes from 'prop-types';

const Anchor = ({ name }) => (
  <a id={name} name={name} href="#top">{/**/}</a>
);

export default Anchor;

Anchor.propTypes = {
  name: PropTypes.string.isRequired,
};
