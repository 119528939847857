const isAppleMobileDevice = /iPad|iPhone|iPod/.test(navigator.userAgent);
const isAndroidDevice = /Android/.test(navigator.userAgent);
const isObvioslyMobileDevice = navigator.userAgent.indexOf('Mobile') !== -1;
const deviceHasOrientaion = typeof window.orientation !== 'undefined';

export const isMobile = () => (isObvioslyMobileDevice || isAppleMobileDevice || isAndroidDevice || deviceHasOrientaion);

export const setDocumentIsMobile = () => {
  if (isMobile()) {
    document.getElementsByTagName('html')[0].className = 'mobile';
  }
};

export const limitScale = () => {
  if (isAppleMobileDevice) {
    window.document.addEventListener('touchmove', (e) => {
      if (e.scale !== 1) {
        e.preventDefault();
      }
    }, { passive: false });
  }
};
