import React from 'react';
import PropTypes from 'prop-types';

function CarouselMainDisplay({ activeImage }) {
  const { file, text } = activeImage;

  return (
    <div className="carouselContainer">
      <div>
        <img src={file} alt="" className="image" />
      </div>
      <div>
        <span>{text}</span>
      </div>
    </div>
  );
}

export default CarouselMainDisplay;

CarouselMainDisplay.propTypes = {
  activeImage: PropTypes.object.isRequired, /* eslint-disable-line react/forbid-prop-types */
};
