import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import useScrollPosition from '@react-hook/window-scroll';

import { offset, getBoundary, isInView } from '../../utils/position';
import { setFadeWrapperOpacity } from '../../utils/EffectManager';

const FadeWrapper = ({
  center,
  fade, fadeIn, fadeOut,
  threshold, thresholdIn, thresholdOut,
  ppf, fps,
  children, ...rest
}) => {
  const refFader = useRef();
  const scrollPosition = useScrollPosition(fps);

  const updateFader = (position) => {
    const fader = refFader.current;
    const { top, bottom } = getBoundary(fader);

    if (!isInView(top, bottom, position)) {
      return;
    }

    const sectOffs = position - offset(fader.closest('section')).top;
    setFadeWrapperOpacity(fader, sectOffs, ppf);
  };

  useEffect(() => {
    updateFader(scrollPosition);
  }, []);

  useEffect(() => {
    updateFader(scrollPosition);
  }, [scrollPosition]);

  return (
    <div
      ref={refFader}
      className="fade-wrapper"
      data-center={center}
      data-fadeout={fadeOut || fade}
      data-fadein={fadeIn || fade}
      data-thresholdin={thresholdIn || threshold}
      data-thresholdout={thresholdOut || threshold}
      {...rest}
    >
      {children}
    </div>
  );
};

export default FadeWrapper;

FadeWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  center: PropTypes.number.isRequired,
  fade: PropTypes.number,
  fadeIn: PropTypes.number,
  fadeOut: PropTypes.number,
  threshold: PropTypes.number,
  thresholdIn: PropTypes.number,
  thresholdOut: PropTypes.number,
  ppf: PropTypes.number,
  fps: PropTypes.number,
};

FadeWrapper.defaultProps = {
  threshold: 1,
  fade: 20,
  fadeIn: undefined,
  fadeOut: undefined,
  thresholdIn: undefined,
  thresholdOut: undefined,
  ppf: 40,
  fps: 30,
};
