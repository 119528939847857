import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import useScrollPosition from '@react-hook/window-scroll';
import { isInView, getBoundary } from '../../utils/position';

const CoverVideo = ({
  file, size, loop, className, ...rest
}) => {
  const refWrapper = useRef();
  const scrollPosition = useScrollPosition(15);

  const onlyPlayWhenInView = () => {
    const { top, bottom } = getBoundary(refWrapper.current);
    const video = refWrapper.current.querySelector('video');

    if (isInView(top, bottom, scrollPosition)) {
      if (video.paused) {
        try {
          video.play();
        } catch (e) {
          // caught play probably stopped by pause
        }
      }
    } else if (!video.paused) {
      video.pause();
    }
  };

  useEffect(() => {
    onlyPlayWhenInView();
  }, []);

  useEffect(() => {
    onlyPlayWhenInView();
  }, [scrollPosition]);

  return (
    <div ref={refWrapper} className={classnames('fullscreenVideo', className)} {...rest}>
      <video tabIndex="0" autobuffer="autobuffer" preload="auto" playsInline autoPlay muted loop={loop}>
        <source type="video/mp4" src={`./assets/videos/${file}-${size}.mp4`} />
        <p>Sorry, your browser does not support the &lt;video&gt; element.</p>
      </video>
    </div>
  );
};

export default CoverVideo;

CoverVideo.propTypes = {
  file: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  loop: PropTypes.bool,
  className: PropTypes.string,
};

CoverVideo.defaultProps = {
  loop: true,
  className: '',
};
