import React from 'react';
import PropTypes from 'prop-types';
import Anchor from './Anchor';

const Chapter = ({ num, text }) => (
  <article className="scroll-chapter">
    <Anchor name={`ch${num}`} />
    <div>
      <p>{text}</p>
      <div>
        {num}
      </div>
    </div>
  </article>
);

export default Chapter;

Chapter.propTypes = {
  num: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
