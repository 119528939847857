import { getDistance } from './position';

const locateLeadInsAndOuts = (sectionVideo, index) => {
  const section = sectionVideo.closest('section');
  const nxt = section.nextSibling;
  if (nxt.length === 0) return;

  sectionVideo.classList.add(`video_${index}`);

  const leadinSection = section.querySelector('.leadin');
  const xleadin = leadinSection && leadinSection.nextSibling.length
    ? getDistance(leadinSection, leadinSection.nextSibling) : 0;

  const leadoutSection = section.querySelector('.leadout');
  const xleadout = leadoutSection
    ? getDistance(nxt, leadoutSection) : 0;

  sectionVideo.setAttribute('data-leadin', xleadin);
  sectionVideo.setAttribute('data-leadout', xleadout);
};

const setLoadingWasComplete = () => document.querySelector('body').classList.remove('loading');
const documentIsReady = () => (document.readyState === 'complete');

let waitTimeout = null;

const setLoadingCompleteIn = (milliseconds) => {
  if (waitTimeout) {
    clearTimeout(waitTimeout);
  }

  waitTimeout = setTimeout(() => {
    // window.scrollTo({ top: 0 });
    if (documentIsReady()) {
      setLoadingWasComplete();
    } else {
      document.onreadystatechange = () => {
        if (documentIsReady()) {
          setLoadingWasComplete();
          document.onreadystatechange = () => {};
        }
      };
    }
  }, milliseconds);
};

let videoLoadCount = 0;

export const gatherVideoInformation = () => {
  const videos = document.querySelectorAll('aside video');
  if (videos.length === 0) {
    setLoadingCompleteIn(10);
    return;
  }
  setLoadingCompleteIn(3000);
  videos.forEach((sectionVideo, index) => {
    videoLoadCount += 1;
    sectionVideo.addEventListener('loadedmetadata', () => {
      videoLoadCount -= 1;
      if (videoLoadCount <= 0) {
        setLoadingCompleteIn(200);
      }
    });
    locateLeadInsAndOuts(sectionVideo, index);
  });
};

export const playVideosIn = (article) => {
  article.querySelectorAll('.fullscreenVideo video').forEach((video) => {
    try {
      video.play();
    } catch (e) {
      // console.log(e);
    }
  });
};

export const pauseVideosIn = (article) => {
  article.querySelectorAll('.fullscreenVideo video').forEach((video) => {
    try {
      video.pause();
    } catch (e) {
      // console.log(e);
    }
  });
};
