import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Plaque = ({
  children, className, ...rest
}) => (
  <div className={classnames('plaque', 'dark', className)} {...rest}>
    {children}
  </div>
);

export default Plaque;

Plaque.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Plaque.defaultProps = {
  children: null,
  className: '',
};
