/**
  Opaque static section

  Puts a covering background on the screen, grows with the Article children

  type: a string that has a matching css class named {type}Cover
*/
import React from 'react';
import PropTypes from 'prop-types';

const CoverSection = ({ children, type }) => (
  <section className={`${type}Cover`}>
    {children}
  </section>
);

export default CoverSection;

CoverSection.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
};

CoverSection.defaultProps = {
  children: null,
  type: 'dark',
};
