import React from 'react';
import PropTypes from 'prop-types';
import { RWebShare } from 'react-web-share';
import ShareIcon from '../icons/Share_white.svg';

const ShareButton = ({
  text, url, title, ...rest
}) => (
  <div className="share" {...rest}>
    <RWebShare
      data={{ text, url, title }}
      onClick={
        () => console.log('shared successfully!') // eslint-disable-line no-console
      }
    >
      <button type="button"><img src={ShareIcon} alt="Share Icon" /></button>
    </RWebShare>
  </div>
);

export default ShareButton;

ShareButton.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
  title: PropTypes.string,
};

ShareButton.defaultProps = {
  text: 'Striatus 3D concrete printed masonry bridge',
  url: 'https://striatusbridge.com/',
  title: 'Striatus 3D concrete printed masonry bridge',
};
