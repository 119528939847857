import React from 'react';
import ReactDOM from 'react-dom';

import ScrollyTellStory from './app/ScrollyTellIndex';

class AppIndex extends React.Component {
  constructor(props) {
    super(props);

  }



  render() {
    return (<ScrollyTellStory />);
  }
}

ReactDOM.render(<AppIndex />, document.getElementById('app'));
