import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const SubTitle = ({
  children, className, ...rest
}) => (
  <div className={classnames('content-title', className)} {...rest}>
    <h2 className="smallText">
      {children}
    </h2>
  </div>
);

export default SubTitle;

SubTitle.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

SubTitle.defaultProps = {
  children: null,
  className: '',
};
