/**
  Absolute positioned by frame Articles over video

  Each child Article needs data-appearsatframe property

  file: required, name without extension and size of the mp4 video in /assets/video folder
    the complete file name is built up by the name, a dash, the size, and .mp4
  size: required, any of the sizes available for the video

  fps: the video framerate, defaults to 24
  ppf: ppf, how far the scroll moves each frame, defaults to an arbitrary value of 50
*/
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useScrollPosition from '@react-hook/window-scroll';
import { offset } from '../../utils/position';

const getPosition = (el) => {
  const frame = el.getAttribute('data-appearatframe');
  if (frame === null || frame === undefined || frame === '') {
    throw new Error('Missing appear at frame value for Article in AbsVideoSection');
  }
  return parseInt(frame, 10) + 22;
};

const updateHeights = (sect, fps, ppf, duration) => {
  const els = sect;
  const totalFrames = fps * duration;
  els.style.height = `${(totalFrames * ppf)}px`;

  const articles = sect.querySelectorAll('article');
  articles.forEach((article) => {
    const ela = article;
    ela.style.top = `${(getPosition(article) * ppf)}px`;
  });
};

const calculateCurrentTimeFor = (currentOffset, sectionTop, fps, ppf, duration) => {
  const pps = ppf * fps;
  const videoSectionOffset = (currentOffset - sectionTop);
  const videoSecond = videoSectionOffset / pps;

  const twoFramesInSeconds = (1 / fps) * 2; // iOS fix, moving past the end removes video
  const maxTime = duration - twoFramesInSeconds;

  return Math.min(maxTime, videoSecond);
};

const AbsVideoSection = ({
  file, size, fps, ppf, children,
}) => {
  const refWrapper = useRef();
  const scrollPosition = useScrollPosition(fps);

  const [ready, setReady] = useState(false);
  const [video, setVideo] = useState(undefined);
  const [duration, setDuration] = useState(1);

  useEffect(() => {
    const sectionVideo = refWrapper.current.querySelector('aside video');
    setVideo(sectionVideo);
    sectionVideo.addEventListener('loadedmetadata', () => {
      const dur = sectionVideo.duration;
      setDuration(dur);
      sectionVideo.pause();
      sectionVideo.setAttribute('data-duration', dur);
      updateHeights(refWrapper.current, fps, ppf, dur);
      setReady(true);
    });
  }, []);

  useEffect(() => {
    const sectionVideo = video;
    if (!sectionVideo) {
      return;
    }
    if (!ready) {
      return;
    }

    const { top, bottom } = offset(refWrapper.current);

    if (refWrapper.current.classList.contains('active')) {
      if (scrollPosition < (top - window.innerHeight) || scrollPosition > bottom) {
        refWrapper.current.classList.remove('active');
      }
    } else if (scrollPosition >= (top - window.innerHeight)) {
      refWrapper.current.classList.add('active');
    }
  }, [scrollPosition]);

  useEffect(() => {
    const sectionVideo = video;
    if (!sectionVideo) {
      return;
    }

    const sectionTop = offset(refWrapper.current).top;
    const time = calculateCurrentTimeFor(scrollPosition, sectionTop, fps, ppf, duration);

    sectionVideo.currentTime = time;
    sectionVideo.pause();
  }, [scrollPosition]);

  return (
    <section ref={refWrapper} className="video absolute" data-name={file}>
      <aside>
        <video tabIndex="0" autobuffer="autobuffer" preload="auto" autoPlay playsInline muted data-fps={fps} data-ppf={ppf}>
          <source type="video/mp4" src={`./assets/videos/${file}-${size}.mp4`} />
          <p>Sorry, your browser does not support the &lt;video&gt; element.</p>
        </video>
      </aside>
      {children}
    </section>
  );
};

export default AbsVideoSection;

AbsVideoSection.propTypes = {
  children: PropTypes.node,
  file: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  fps: PropTypes.number,
  ppf: PropTypes.number,
};

AbsVideoSection.defaultProps = {
  children: null,
  fps: 24,
  ppf: 50,
};
