import React from 'react';
import { Anchor } from '../components';

const chapters = [];

export const getChapters = () => chapters;

export const addChapter = (chapter, text) => {
  chapters.push({ chapter, text });
  return (
    <Anchor name={chapter} />
  );
};
