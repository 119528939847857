import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CarouselMainDisplay from './carouselComponents/carouselMainDisplay';
import CarouselTray from './carouselComponents/carouselTray';

// images : json [{ file: '', text:'' }]
const Carousel = ({ images, className, ...rest }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const onItemClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className={classnames('carousel', className)} {...rest}>
      <CarouselMainDisplay activeImage={images[activeIndex]} />
      <CarouselTray
        activeIndex={activeIndex}
        images={images}
        onClick={onItemClick}
      />
    </div>
  );
};

export default Carousel;

Carousel.propTypes = {
  className: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({ file: PropTypes.string, text: PropTypes.string }),
  ).isRequired,
};

Carousel.defaultProps = {
  className: '',
};
