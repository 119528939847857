/**
  <LottieAnimation file=[jsonfile] (name,external,[standard attributes]) />

  file: the name of a json file in the assets/lottie folder, unless external is set
  external: if set, the file is an absolute file path
  name: if you reuse a json file, the name need to be specified as a unique name.

  Sticky props
  duration: pixels to stay sticky
  position: position on screen where to stick

  standard attributes: style className etc.
*/
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import lottieWeb from 'lottie-web';
import useScrollPosition from '@react-hook/window-scroll';

import { getBoundary, isInView } from '../../utils/position';

const animations = [];
const durations = [];

const LottieAnimation = ({
  file, name, external, duration, position, renderersettings, className, ...rest
}) => {
  const refWrapper = useRef();
  const refAnimation = useRef();
  const scrollPosition = useScrollPosition(30);

  const animationName = name || file;
  const lottieFileName = external ? file : `./assets/lottie/${file}`;

  useEffect(() => {
    animations[animationName] = lottieWeb.loadAnimation({
      container: refAnimation.current,
      path: lottieFileName,
      renderer: 'svg',
      loop: true,
      autoplay: false,
      name: animationName,
      rendererSettings: renderersettings,
    });

    window.addEventListener('resize', () => {
      animations[animationName].resize();
    });
  }, []);

  useEffect(() => {
    if (!animations[animationName]) {
      return;
    }
    const { top, bottom } = getBoundary(refWrapper.current);
    const topPosition = top;
    const animHeight = (bottom - top);
    if (!durations[animationName]) {
      durations[animationName] = animations[animationName].getDuration(true);
    }
    // for getting the duration of all lottie animations
    // console.log('frames in ', animationName, durations[animationName]);
    const framesPerPixel = durations[animationName] / (animHeight - window.innerHeight);
    const screenPos = scrollPosition - topPosition;

    // check if we are in view
    if (!isInView(top, bottom, scrollPosition)) {
      return;
    }

    const frame = Math.min(Math.max(screenPos * framesPerPixel, 0), durations[animationName]);
    lottieWeb.goToAndStop(frame, true, animationName);
  }, [animations[animationName], scrollPosition]);

  return (
    <div ref={refWrapper} style={{ height: duration }}>
      <div className="sticky" style={{ top: position }}>
        <div ref={refAnimation} className={classnames(className)} {...rest} />
      </div>
    </div>
  );
};

export default LottieAnimation;

LottieAnimation.propTypes = {
  file: PropTypes.string.isRequired,
  name: PropTypes.string,
  external: PropTypes.bool,
  duration: PropTypes.string,
  position: PropTypes.string,
  renderersettings: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  className: PropTypes.string,
};

LottieAnimation.defaultProps = {
  name: undefined,
  external: false,
  duration: 'auto',
  position: '0px',
  renderersettings: {},
  className: '',
};
