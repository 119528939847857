import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import MenuIcon from '../icons/menu.svg';

const MenuItem = (chapter, text) => (
  <li key={`menu-item-${chapter}`}>
    <a href={`#${chapter}`} className="chapter-index" title={`go to ${text}`} alt={text}>{text}</a>
  </li>
);

const MenuLinkItem = (chapter, text) => (
  <li key="menu-item-media">
    <a href={chapter} className="chapter-index" title={`go to ${text}`} alt={text} rel="noreferrer">{text}</a>
  </li>
);

const MenuSplitItem = () => (
  <li className="menu-item-split" key="menu-item-split" />
);

const HamburgerMenu = ({
  icon, side, chapters, ...rest
}) => {
  useEffect(() => {
    const element = document.querySelector('.hamburger-icon');
    element.addEventListener('click', () => {
      const md = document.querySelector('.menu-dropdown');
      md.classList.toggle('active');
    });

    document.querySelectorAll('.chapter-index').forEach((item) => {
      item.addEventListener('click', () => {
        const md = document.querySelector('.menu-dropdown');
        md.classList.remove('active');
      });
    });
  }, []);

  const direction = `${side}-side`;

  return (
    <div className="hamburger-menu" {...rest}>
      <div className="hamburger-icon">
        <img src={icon} alt="Menu Icon" />
      </div>
      <ul className={classnames('menu-dropdown', direction)}>
        {chapters.map((item) => {
          if (item.chapter === 'split') {
            return MenuSplitItem();
          }
          if (item.chapter.indexOf('.html') !== -1) {
            return MenuLinkItem(item.chapter, item.text);
          }
          return MenuItem(item.chapter, item.text);
        })}
      </ul>
    </div>
  );
};

export default HamburgerMenu;

HamburgerMenu.propTypes = {
  chapters: PropTypes.arrayOf(
    PropTypes.shape({ chapter: PropTypes.string, text: PropTypes.string }),
  ).isRequired,
  icon: PropTypes.string,
  side: PropTypes.string,
};

HamburgerMenu.defaultProps = {
  icon: MenuIcon,
  side: 'left',
};
