import React from 'react';
import PropTypes from 'prop-types';

const StickyWrapper = ({
  children, position, duration, gap
}) => (
  <>
    <div style={{ height: duration }}>
      <div className="sticky" style={{ top: position }}>
        {children}
      </div>
    </div>
    <div style={{ height: gap }} />
  </>
);

export default StickyWrapper;

StickyWrapper.propTypes = {
  children: PropTypes.node,
  position: PropTypes.string,
  duration: PropTypes.string,
  gap: PropTypes.string,
};

StickyWrapper.defaultProps = {
  children: null,
  position: '50%',
  duration: '500px',
  gap: '500px',
};
