import { isMobile } from './mobileDetection';

export const getWindowWidth = () => window.innerWidth;

let videoSize;

const BIG_VIDEO = 1600;
const MEDIUM_VIDEO = 1600;
const SMALL_VIDEO = 800;

export const getVideoSize = () => {
  if (videoSize) {
    return videoSize;
  }

  if (!isMobile()) {
    // videoSize = isSafariBrowser ? MEDIUM_VIDEO : BIG_VIDEO;
    videoSize = BIG_VIDEO;
    return videoSize;
  }

  const ww = getWindowWidth();
  if (ww < 600) {
    videoSize = SMALL_VIDEO;
  } else {
    videoSize = MEDIUM_VIDEO;
  }
  return videoSize;
};
