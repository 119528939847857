const calculateOpacity = (away, steps, hold) => {
  if (away >= steps) {
    return 0;
  }
  if (away < steps) {
    return hold >= steps ? 1.0 : Math.min(1.0, (steps - away) / (steps - hold));
  }
  return 0;
};

export const setFadeWrapperOpacity = (wrapper, sectOffs, ppf) => {
  const midpoint = wrapper.getAttribute('data-center') * ppf;
  const stepsin = wrapper.getAttribute('data-fadein') * ppf;
  const stepsout = wrapper.getAttribute('data-fadeout') * ppf;
  const holdin = wrapper.getAttribute('data-thresholdin') * ppf;
  const holdout = wrapper.getAttribute('data-thresholdout') * ppf;
  const dist = midpoint - sectOffs;
  const away = Math.abs(dist);

  let opacity = 0;
  if (dist > 0) {
    opacity = calculateOpacity(away, stepsin, holdin);
  } else if (dist < 0) {
    opacity = calculateOpacity(away, stepsout, holdout);
  } else if (away === 0) {
    opacity = 1;
  }
  const w = wrapper;
  w.style.opacity = opacity;
};
