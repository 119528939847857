import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Header = ({
  smallText, bigText, className, ...rest
}) => (
  <div className={classnames('content-header', className)} {...rest}>
    <div className="smallText">{smallText}</div>
    <div className="bigText">{bigText}</div>
  </div>
);

export default Header;

Header.propTypes = {
  smallText: PropTypes.string.isRequired,
  bigText: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Header.defaultProps = {
  className: '',
};
