import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

function CarouselTray({
  images, activeIndex, onClick, slideLeft, slideRight,
}) {

  const RenderedTray = images.map((item, index) => {
    const activeClass = index === activeIndex ? 'active' : null;
    return (
      <div className={classnames('trayItem', activeClass)}
        key={item.file}
        onClick={(e) => onClick(index)}
      >
        <img src={item.file} alt="" />
      </div>
    );
  });

  return (
    <div className="carouselTrayContainer">
      {slideLeft}
      {RenderedTray}
      {slideRight}
    </div>
  );
}

export default CarouselTray;

CarouselTray.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({ file: PropTypes.string, text: PropTypes.string })),
  activeIndex: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  slideLeft: PropTypes.func.isRequired,
  slideRight: PropTypes.func.isRequired,
};

CarouselTray.defaultProps = {
  images: [],
  activeIndex: 0,
};
