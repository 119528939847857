import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import useScrollPosition from '@react-hook/window-scroll';
import { getBoundary, isInView } from '../utils/position';
import { removeClassFromElementMatching } from '../utils/domHelper';
import { playVideosIn, pauseVideosIn } from '../utils/VideoManager';
import { dofadeInFullscreenImage, setImageFullyInView } from '../utils/ImageManager';

const oneShotAnimationsInView = (article, currentOffset) => {
  dofadeInFullscreenImage(article, currentOffset);
  article.querySelectorAll('img').forEach((img) => {
    setImageFullyInView(img, currentOffset);
  });
};

const handleArticleActions = (article, currentOffset) => {
  const { top, bottom } = getBoundary(article);
  if (isInView(top, bottom, currentOffset)) {
    if (article.classList.contains('inView')) {
      return;
    }
    oneShotAnimationsInView(article, currentOffset);

    article.classList.add('inView');

    playVideosIn(article);
  } else if (article.classList.contains('inView')) {
    pauseVideosIn(article);

    article.classList.remove('inView');
    removeClassFromElementMatching('inView', article, 'img');
  }
};

const Article = ({
  children, ...rest
}) => {
  const articleRef = useRef();
  const scrollPosition = useScrollPosition(30);

  useEffect(() => {
    if (!articleRef) {
      return;
    }
    handleArticleActions(articleRef.current, scrollPosition);
  }, [articleRef, scrollPosition]);

  return (
    <article ref={articleRef} {...rest}>
      {children}
    </article>
  );
};

export default Article;

Article.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Article.defaultProps = {
  children: null,
  className: '',
};
