import React, { useEffect } from 'react';

import {
  Article,
  ScrollyTeller,
  CoverSection,
  TextContent,
  StickyHeader,
  LogoMenu,
  ShareButton,
  Copyright,
  CoverVideo,
  ModelViewer,
  Header,
  VimeoVideo,
  LayerWrapper,
  HamburgerMenu,
  ParallaxImage,
  Carousel,
} from '../lib/components';

import { addChapter, getChapters } from '../lib/utils/chapters';

import { limitScale, setDocumentIsMobile } from '../lib/utils/mobileDetection';
import ScrollyTellEngine from '../lib/utils/ScrollyTellEngine';
import MenuIcon from '../assets/icons/menu_logo.svg';

import { getVideoSize } from '../lib/utils/sizes';
// const getVideoSize = () => 1600; // hardcoded for now

import firstCarousel from '../assets/carousels/firstcarousel.json';

// frame when text is just appearing at the botton of the screen
const baseRate = 40;

const pixelsPerFrame = 24;
const fadeMult = baseRate / pixelsPerFrame;

export default function ScrollyTellIndex() {
  useEffect(() => {
    limitScale();
    setDocumentIsMobile();
    const engine = new ScrollyTellEngine();
    engine.run();
  });

  return (
    <ScrollyTeller>
      {addChapter('part1', 'INTRODUCTION')}
      <CoverSection>
        <Article name="Intro-img">
          <LayerWrapper className="introcontainer">
            <ParallaxImage style={{ top: 0, left: 0, height: '90vh' }} file="./assets/images/bridge-intro.jpg"/>
            <div className="introtxtoverimg">
              <p className="bigtxt">Striatus</p>
              <p className="smalltxt">3D Concrete Printed masonry</p> 
            </div>
          </LayerWrapper>
        </Article>
      </CoverSection>

      <CoverSection type="dark">
        <Article>
          <div className="intrologos">
            <img alt="partner logos" src="./assets/images/intro_logos.png" />
          </div>
        </Article>
      </CoverSection>

      <CoverSection type="dark" name="Intro-vid1">
        <Article>
          <CoverVideo file="Striatus_loop_v001" size={1600} />
        </Article>
      </CoverSection>

      <CoverSection type="dark" name="Intro-title2">
        <Article style={{minHeight:'100vh', display:'flex', flexDirection:'column', alignItems:'center'}}>
          <div className="titlecontainer">
            <p className="introtitle">
              Striatus
            </p>
            <p  className="introtitlep">Project by the Block Research Group (BRG) at ETH Zurich 
              and Zaha Hadid Architects Computation and Design 
              Group (ZHACODE), in collaboration with incremental3D 
              (in3D), made possible by Holcim.
            </p>
          </div>
        </Article>
      </CoverSection>

      {addChapter('part2', 'OUTLOOK')}

      <CoverSection type="dark" name="Outlook-title1">
        <Article style={{height:'100vh', display: 'flex', alignItems: 'center'}}>
          <Header bigText="Outlook" smallText=""/>
        </Article>
      </CoverSection>

      <CoverSection type="dark" name="Intro vidtitle-1">
        <Article style={{minHeight:'100vh', display:'flex', flexDirection:'column', alignItems:'center'}}>
          <div className="titlecontainer">
            <p className="introtitle">
              A new language for concrete
            </p>
            <p className="introtitlep"> The first of its kind, Striatus establishes a new language for concrete 
              that is digital, environmentally advanced and circular by design. 
              <br/>
              It uses concrete at its best by placing it precisely where needed with minimal 
              material use - doing more with less with no compromise on 
              performance and aesthetics. Here, concrete is unreinforced, holding 
              through compression, structurally informed, fabrication-aware, 
              precisely placed and ecologically responsible.
              <br/>
              <br/>
              <span className="attributiontext">Photos of completed bridge in Giardini Marinaressa, Venice © Studio NAARO</span>
            </p>
          </div>
        </Article>
      </CoverSection>

      <CoverSection type="dark" name="Vimeo1">
        <VimeoVideo vimeoID="574561715" style={{ backgroundColor: 'black' }} />
      </CoverSection>

      <CoverSection type="dark" name="Intro-title3">
        <Article className='textcontaineroutlook'>
          <div style={{color:'white', paddingTop:'10vh', width:'100%'}}>
            <p className="titleitalic" style={{fontStyle: 'italic'}}>
              Reduce Reuse Recycle
            </p>
          </div>
          <div>
            <p className="subtitleitalic">
              Sustainable digital concrete
            </p>
            <p  className="txtitalic">
              <br/>
              Striatus represents how the 3 R’s of the circular economy - <strong>Reduce, Reuse, Recycle, </strong>
              can be applied consistently to concrete structures.
              <br/>
              <br/>
              <strong>REDUCE</strong>: Striatus is designed with minimal material use for maximum strength with no waste.
              <br/><br/>
              <strong>REUSE</strong>: all components are designed to be disassembled and reused.
              <br/><br/>
              <strong>RECYCLE</strong>: all components can be easily recycled, with limited energy and cost. 
              <br/>The recycling process is simple and cost-efficient as no materials sorting is 
              needed, due to the absence of reinforcements, glue or binders.
            </p>
          </div>
        </Article>
      </CoverSection>

      {addChapter('part3', 'DESIGN')}

      <CoverSection type="dark" name="Outlook-title1">
        <Article style={{height:'100vh', display: 'flex', alignItems: 'center'}}>
          <Header bigText="Design" smallText=""/>
        </Article>
      </CoverSection>

      <CoverSection type="dark" name="Outlook-vidtitle1">
        <Article style={{minHeight:'100vh', display:'flex', flexDirection:'column', alignItems:'center'}}>
          <div className="titlecontainer">
            <p className="introtitle">
              Strength through geometry
            </p>
            <p  className="introtitlep">Achieving strength through geometry, the Striatus bridge stands solely 
              through compression without reinforcement. Using advanced technologies, 
              from computational design and engineering to robotic manufacturing, 
              Striatus revives traditional master builders’ techniques to create a high-tech masonry structure.
              <br/>
              <br/>
              <span className="attributiontext">Key structural principles of Striatus © BRG, ZHA,IN3D</span>
            </p>
          </div>
        </Article>
      </CoverSection>

      <CoverSection type="dark" name="vimeo2">
        <VimeoVideo vimeoID="573140820" style={{ backgroundColor: 'black' }} />
      </CoverSection>

      <CoverSection type="dark" name="Outlook-vidtitle2">
        <Article style={{minHeight:'100vh', display:'flex', flexDirection:'column', alignItems:'center'}}>
          <div className="titlecontainer">
            <p className="introtitle">
              3D Concrete Printed masonry
            </p>
            <p  className="introtitlep">Striatus follows a masonry logic on two levels. Globally, 
              the bridge behaves as a series of 
              leaning unreinforced voussoirarches, with discretisations orthogonal to the dominant 
              force flow in compression, following the same structural principles as the arched Roman 
              bridges in stone. Locally, on the level of the voussoir, the 3D-printed concrete layers 
              behave as traditional brick masonry, similar to the inclined rows of bricks in Nubian or 
              Mexican vaulting.
              <br/>
              <br/>
              <span className="attributiontext">Robustness load bearing capacity of 3DCP masonry © BRG, ZHA,IN3D</span>
            </p>
          </div>
        </Article>
      </CoverSection>

      <CoverSection type="dark" name="vimeo3">
        <VimeoVideo vimeoID="573140431" style={{ backgroundColor: 'black' }} />
      </CoverSection>

      <CoverSection type="dark" name="Outlook-vidtitle3">
        <Article style={{minHeight:'100vh', display:'flex', flexDirection:'column', alignItems:'center'}}>
          <div className="titlecontainer">
            <p className="introtitle">
              Robotic 3D printing of concrete
            </p>
            <p  className="introtitlep">
            Unlike typical extrusion 3D printing in simple horizontal layers, Striatus uses a two-component (2K) 
            concrete ink with corresponding printing head and pumping 
            arrangement to precisely print non-uniform and non-parallel layers. This new generation 
            of 3D concrete printing in combination with the arched masonry design allows the 
            resulting components to be used structurally without any reinforcement or post-tensioning. 
            For Striatus, Holcim developed a specific, custom-made proprietary 3D-printing ink from its Tector® 
            3D Build range of mortar inks.
              <br/>
              <br/>
              <span className="attributiontext">Non-parallel, force-aligned print path generation and concrete printing © BRG,ZHA,IN3D</span>
            </p>
          </div>
        </Article>
      </CoverSection>

      <CoverSection type="dark" name="vimeo4">
        <VimeoVideo vimeoID="573140021" style={{ backgroundColor: 'black' }} />
      </CoverSection>

      <CoverSection type="dark" name="Carousel Title">
      <Article style={{minHeight:'100vh', display:'flex', flexDirection:'column', alignItems:'center'}}>
          <div className="titlecontainer">
            <p className="introtitle">
              Computational design to construction integration
            </p>
            <p  className="introtitlep">Integrating design, engineering, fabrication and construction, Striatusredefines 
              conventional interdisciplinary relations. The precise manufacturing of the blocks was 
              enabled by well-defined data exchange between the various domain-specific software 
              toolchains involved in the process. This co-development approach was facilitated through 
              the use of COMPAS, an open-source computational framework for collaboration and 
              research in the Architecture, Engineering &#38; Construction (AEC) industry, which enabled 
              the fluent interaction among the key players of the project, working together in five 
              different countries, under a very tight schedule and budget, at a time in which travelling 
              was not possible.
              <br/>
              <br/>
              <span className="attributiontext">Integrating design, engineering, fabrication and construction aspects © BRG, ZHA,IN3D</span>
            </p>
          </div>
        </Article>
      </CoverSection>

      <CoverSection type="dark" name="vimeo5">
        <VimeoVideo vimeoID="573105882" style={{ backgroundColor: 'black' }} />
      </CoverSection>

      {addChapter('part4', 'BUILD')}

      <CoverSection type="dark" name="Intro-title3">
        <Article className='textcontaineroutlook'>
          <div style={{color:'white', paddingTop:'10vh', width:'100%'}}>
            <p className="titleitalic">
              Build
            </p>
          </div>
          <div>
            <p className="subtitleitalic" style={{paddingTop:'1em', textAlign:'center'}}>
            3D Concrete Printing and assembly
            </p>
          </div>
        </Article>
      </CoverSection>

      <CoverSection type="dark" name="Project-vidtitle1">
        <Article style={{minHeight:'100vh', display:'flex', flexDirection:'column', alignItems:'center'}}>
          <div className="titlecontainer">
            <p className="introtitle">
              3D concrete printing
            </p>
            <p  className="introtitlep">
              <span className="attributiontext">Robotic 3D printing of concrete © BRG, ZHA,IN3D</span>
            </p>
          </div>
        </Article>
      </CoverSection>

      <CoverSection type="dark" name="vimeo6">
        <VimeoVideo vimeoID="573105446" style={{ backgroundColor: 'black' }} />
      </CoverSection>

      <CoverSection type="dark" name="Project-vidtitle2">
        <Article style={{minHeight:'100vh', display:'flex', flexDirection:'column', alignItems:'center'}}>
          <div className="titlecontainer">
            <p className="introtitle">
              Assembly
            </p>
            <p  className="introtitlep">
              <span className="attributiontext">On-site assembly of pre-fabricated, concrete printed blocks © BRG, ZHA,IN3D</span>
              <br/>
              <span className="attributiontext">Video footage of completed bridge in Giardini Marinaressa, Venice © Studio NAARO</span>
            </p>
          </div>
        </Article>
      </CoverSection>

      <CoverSection type="dark" name="vimeo7">
        <VimeoVideo vimeoID="573104959" style={{ backgroundColor: 'black' }} />
      </CoverSection>

      <CoverSection type="dark" name="vimeo10">
        <VimeoVideo vimeoID="573104179" style={{ backgroundColor: 'black' }} />
      </CoverSection>

      {addChapter('part5', 'RESOURCES')}

      <CoverSection type="dark" name="Resources-title1">
        <Article style={{height:'100vh', display: 'flex', alignItems: 'center'}}>
          <Header bigText="Resources" smallText=""/>
        </Article>
      </CoverSection>

      <CoverSection type="light">
        <Article>
          <div style={{height:'30vh'}}/>
        </Article>
      </CoverSection>
      
      <CoverSection type="light">
        <Article className="textresource">
          <div className="titleresource">
            Fact Sheet
          </div>
          <div className="arqr">
            <ModelViewer file="AR_bridge_210623_v4" />
            <div style={{ width: '100%', textAlign: 'center', justifyContent: 'center' }}>
              <a href="arqr.html"><img src="./assets/images/qrcode_Striatus_AR_Bridge_1.png" style={{ width: '300px', marginBottom: '1.5em' }} alt="qrcode" />
              </a>
            </div>
          </div>
          <div className="arqrtext">
            <ul style={{textAlign:'left', width:'100%'}}>
              <li style={{fontWeight:'bold', paddingBottom:'1em'}}> Key Facts about Striatus</li>
              <li style={{fontWeight:'bold', paddingBottom:'1em', paddingTop:'1.5em'}}>Overall</li>
              <li>Maximum height = 3.5 m</li>
              <li>Minimum head height = 2.2 m</li>
              <li>Total surface area = 216 sqm</li>
              <li>Total covered area = 50 sqm</li>
              <li>Longest span = 15.10 m</li>
              <li>Shortest span = 4.95 m</li>
              <li>Material density of 3D-printed concrete = ~2350 kg/m³</li>
              <li>Total mass of the structure</li>
              <li style={{paddingLeft:'2em'}}>3DCP blocks = 24.5 ton</li>
              <li style={{paddingLeft:'2em'}}>footings, tension ties = 5.2 ton</li>
              <li style={{fontWeight:'bold', paddingBottom:'1em', paddingTop:'1.5em'}}>Voussoirs / Blocks</li>
              <li>Number of voussoirs = 53</li>
              <li>Thicknesses of voussoirs = 0.05m - 0.35m</li>
              <li>Volume (solid) of voussoirs = 19.18 m³</li>
              <li style={{fontWeight:'bold', paddingBottom:'1em', paddingTop:'1.5em'}}>3D-printed concrete</li>
              <li>3D-printing path lengths (per block) = 602m - 1754m</li>
              <li>3D-printing total path length (all blocks) = 58 km</li>
              <li>3D-printing total print time (all blocks) = 84 hours</li> 
              <li>(incl. pre-print and post-print procedures)</li>
              <li>3D-printing layer heights = 4.46mm - 11.98mm</li>
              <li>3D-printed mass per block = 217 kg - 783 kg</li>
              <li>3D-printing widths</li>
              <li style={{paddingLeft:'2em'}}>Deck Blocks = 25 mm - 50 mm</li>
              <li style={{paddingLeft:'2em'}}>Balustrade Blocks = 40 mm</li>
            </ul>
          </div>
        </Article>
      </CoverSection>

      <CoverSection type="light" name="Resource-2">
        <Article className="textresource">
          <div className="titleresource">
            Credits
          </div>
          <div className="contentresource">
            <ul>
              <li>
                <strong>Striatus</strong>
                <p>An arched 3D-concrete-printed masonry bridge
                  <br/><br/>
                  Project by the Block Research Group (BRG) at ETH 
                  Zurich and Zaha Hadid Architects Computation and 
                  Design Group (ZHACODE), in collaboration with 
                  incremental3D (in3D), made possible by Holcim</p>
              </li>
              <li>
                <strong>Design</strong>
                <p>ZHACODE: Jianfei Chu, Vishu Bhooshan, Henry 
                  David Louth, Shajay Bhooshan,Patrik Schumacher
                  ETHZ BRG: Tom Van Mele, Alessandro Dell’Endice, 
                  Philippe Block</p>
              </li>
              <li>
                <strong>Structural engineering</strong>
                <p>ETHZ BRG: Tom Van Mele, Alessandro Dell’Endice, 
                  Sam Bouten, Philippe Block</p>
              </li>
              <li>
                <strong>Fabrication design</strong>
                <p>ETHZ BRG: Shajay Bhooshan, Alessandro Dell’Endice,
                  Sam Bouten, Chaoyu Du, Tom Van Mele
                  ZHACODE: Vishu Bhooshan, Philip Singer, Tommaso Casucci</p>
              </li>
              <li>
                <strong>3D concrete printing</strong>
                <p>in3D: Johannes Megens, Sandro Sanin, Nikolas
                  Janitsch, Janos Mohacsi, Georg Grasser
                </p>
              </li>
              <li>
                <strong>Concrete material development</strong>
                <p>Holcim: Christian Blachier, Marjorie Chantin-Coquard, Helene 
                  Lombois-Burger, Francis Steiner <br/>
                  LafargeHolcimSpain: Benito Carrion, Jose Manuel Arnau</p>
              </li>
            </ul>
          </div>
          <div className="contentresource">
            <ul>
              <li>
                <strong>Assembly</strong>
                <p>Bürgin Creations ETHZ BRG: Theo Bürgin, Semir Mächler, Calvin Graf <br/>
                  ETH BRG: Alessandro Dell’Endice, Tom Van Mele
                </p>
              </li>
              <li>
                <strong>Logistics</strong>
                <p>ETH BRG: Alessandro Dell’Endice, Tom Van Melez<br/>
                  HolcimSwitzerland &#38; Italy: Michele Alverdi<br/>
                  HolcimSpain: Ricardo de Pablos, José Luis Romero</p>
              </li>
              <li>
                <strong>Additional partners</strong>
                <p>Ackermann GmbH [CNC timber formwork]<br/>
                  L2F Architettura [site measurements]<br/>
                  Pletscher [steel supports]<br/>
                  ZB Laser [lasercutting neoprene]<br/>
                  XXX [recycled rubber deck]</p>
              </li>
              <li>
                <strong>Documentation</strong>
                <p>ZHACODE: Jianfei Chu, Cesar Fragachan, Vishu Bhooshan, Philip 
                  Singer, Edward Meyers, Shajay Bhooshan<br/>
                  ETH BRG: Tom Van Mele, Alessandro Dell’Endice, Philippe Block<br/>
                  In3D: Alexander Gugitscher, Sandro Sanin, Nikolas Janitsch<br/>
                  naaro LBS Fotografia
                </p>
              </li>
            </ul>
          </div>
        </Article>
      </CoverSection>

      <CoverSection type="dark" name="Resource-4">
        <Article className="textresource">
          <div className="titleresource" style={{color:'white'}}>
            Assets
          </div>
          <div className="contentresource">
            <ul style={{color:'white'}}>
              <li>
                <strong>Press pack(texts &#38; images) </strong>
                <p><a href="https://www.dropbox.com/sh/2v1kt01icbgnnp2/AAB1R13pqI73fISrOVn-7c10a?dl=0" style={{color: 'white'}} >Press pack</a> 
                </p>
              </li>
              <li>
                <strong>Videos</strong>
                <p> 
                  <a href="https://vimeo.com/57314121" style={{color: 'white'}}>Project video (full - 4.5min) 
                  </a>
                  <br/>
                  <a href="https://vimeo.com/576432389"style={{color: 'white'}}>Project video (trailer - 1min)</a>
                  <br/>
                  <a href="https://www.youtube.com/watch?v=IRwXJaXgw8s"style={{color: 'white'}}>Captioned summary video ETH</a>
                </p>
              </li>
              <li>
                <strong>Website</strong>
                <p><a href="https://www.striatusbridge.com" style={{color: 'white'}}>Project website</a></p>
              </li>
            </ul>
          </div>
          <div className="contentresource"></div>
        </Article>
      </CoverSection>

      <CoverSection type="light">
        <Article>
          <div className="footerlogos">
            <img alt="partner logos" src="./assets/images/partners_black.png" />
          </div>
        </Article>
      </CoverSection>

      <CoverSection type="light">
        <Article className="partnerImagesHolder">
          <div>
            <div style={{height:'1em'}}/>
            <div className="contactEmail">
              <span><a href="mailto:info@striatusbridge.com">info@striatusbridge.com</a></span>
            </div>
            <div style={{height:'1em'}}/>
            <Copyright />
          </div>
        </Article>
      </CoverSection>

      <StickyHeader>
        <LogoMenu target="#footer" icon={MenuIcon} />
        <div style={{ display: 'inline-flex', height: '100%' }}>
          <ShareButton/>
          <HamburgerMenu chapters={getChapters()} side="right" style={{ display: 'inline-block' }}/>
        </div>
      </StickyHeader>
    </ScrollyTeller>
  );
}
