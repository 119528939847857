import React from 'react';
import PropTypes from 'prop-types';

const ScrollyTeller = ({ children }) => (
  <div id="scrollwrapper">
    <div id="scrollyteller">
      {children}
    </div>
  </div>
);

export default ScrollyTeller;

ScrollyTeller.propTypes = {
  children: PropTypes.node,
};

ScrollyTeller.defaultProps = {
  children: null,
};
