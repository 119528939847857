import React from 'react';
import PropTypes from 'prop-types';
import MenuIcon from '../icons/Striatus_logo.svg';

const LogoMenu = ({
  target, icon, ...rest
}) => (
  <div className="logo-menu" {...rest}>
    <a href={target} className="logo-link" rel="noreferrer">
      <img src={icon} alt="Menu Icon" />
    </a>
  </div>
);

export default LogoMenu;

LogoMenu.propTypes = {
  target: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

LogoMenu.defaultProps = {
  icon: MenuIcon,
};
