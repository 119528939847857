import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const ClearPlaque = ({
  children, className, ...rest
}) => (
  <div className={classnames('plaque', 'clear', className)} {...rest}>
    {children}
  </div>
);

export default ClearPlaque;

ClearPlaque.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

ClearPlaque.defaultProps = {
  children: null,
  className: '',
};
