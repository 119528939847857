import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const CoverImage = ({ file, className, ...rest }) => (
  <div className={classnames('fullscreenImage', className)} {...rest}>{file}</div>
);

export default CoverImage;

CoverImage.propTypes = {
  file: PropTypes.string.isRequired,
  className: PropTypes.string,
};

CoverImage.defaultProps = {
  className: '',
};
