import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const ModelViewer = ({
  file, children, className, exposure, shadowIntensity, ...rest
}) => (
  <div className={classnames('modelviewer', className)} {...rest}>
    <model-viewer
      camera-controls
      disable-zoom
      ar-modes="scene-viewer quick-look"
      quick-look-browsers="safari chrome"
      auto-rotate=""
      autoplay=""
      ar=""
      data-js-focus-visible=""
      interaction-prompt="auto"
      ar-status="not-presenting"
      style={{ width: '100%', height: '100%' }}
      ios-src={`./assets/ar-models/${file}.usdz`}
      src={`./assets/ar-models/${file}.glb`}
      alt="A 3D model"
      exposure={exposure}
      shadow-intensity={shadowIntensity}
    />
  </div>
);

export default ModelViewer;

ModelViewer.propTypes = {
  file: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  exposure: PropTypes.number,
  shadowIntensity: PropTypes.number,
};

ModelViewer.defaultProps = {
  children: null,
  className: '',
  exposure: 1,
  shadowIntensity: 1,
};
