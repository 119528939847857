/**
  Clear static section

  Puts a section with the default background on the screen, grows with the Article children
*/
import React from 'react';
import PropTypes from 'prop-types';

const ClearSection = ({ children }) => (
  <section>
    {children}
  </section>
);

export default ClearSection;

ClearSection.propTypes = {
  children: PropTypes.node,
};

ClearSection.defaultProps = {
  children: null,
};
