import React from 'react';
import PropTypes from 'prop-types';
import EndStory from '../EndStory';

const StickyHeader = ({ children }) => (
  <>
    <EndStory />
    <header>
      {children}
    </header>
  </>
);

export default StickyHeader;

StickyHeader.propTypes = {
  children: PropTypes.node,
};

StickyHeader.defaultProps = {
  children: null,
};
